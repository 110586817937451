<template>
  <div class="row">
    <div class="col-12 card card-custom">
      <div class="card-body py-0 border-bottom">
        <ul class="nav nav-tabs nav-tabs-line border-bottom-0" role="tablist">
          <li class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/health/maintenance' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'tools']" />
                </i>
              </span>
              <span class="nav-text">Maintenance</span>
            </router-link>
          </li>

          <li v-if="sunbedHasTemperatures && ($auth.isResourceFromIndex('users') && $auth.hasPermission('equipment.statistics.temperatures'))" class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/health/temperatures' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'thermometer-half']" />
                </i>
              </span>
              <span class="nav-text">Temperatures</span>
            </router-link>
          </li>

          <li class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/health/modules' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'microchip']" />
                </i>
              </span>
              <span class="nav-text">Circuit boards</span>
            </router-link>
          </li>

          <li class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/health/errors' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
                </i>
              </span>
              <span class="nav-text">Errors</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="card-body px-0 px-lg-9">
        <router-view
          ref="router-view"
          :equipment="equipment"
          :device="device"
        />
      </div>
    </div>
  </div>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import { SunbedHealth } from '@/libs/classes/equipment';

export default {
  mixins: [equipmentMixin],
  computed: {
    sunbedHasTemperatures () {
      if (!this.equipment) {
        return false;
      }

      const sunbedHealth = SunbedHealth.find((maintenanceHours) => maintenanceHours.model.includes(this.equipment.model));
      if (!sunbedHealth) {
        return false;
      }

      return !!sunbedHealth.temperatures;
    },
  },
};
</script>
